<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Personal Information
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Update your personal information</span
        >
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-success d-none d-sm-block"
          @click="update()"
          ref="kt_save_changes"
        >
          <span class="">Save Changes</span>
        </button>
        <button
          class="btn btn-success d-block d-sm-none"
          @click="update()"
          ref="kt_save_changes"
          v-b-tooltip.hover
          title="Save Changes"
        >
          <i class="far fa-paper-plane"></i>
        </button>
      </div>
    </div>
    <form class="form">
      <div class="card-body">
        <h5 class="font-weight-bold mb-6">Customer Information</h5>
        <div class="separator separator-dashed my-4"></div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >First Name</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              type="text"
              class="form-control form-control-lg form-control-solid"
              v-model.trim="$v.form.firstname.$model"
              :state="validateState('firstname')"
            ></b-form-input>
            <b-form-invalid-feedback v-if="$v.form.firstname.$error">
              Firstname is required and at least 4 character.
            </b-form-invalid-feedback>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Last Name</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              type="text"
              class="form-control form-control-lg form-control-solid"
              v-model.trim="$v.form.lastname.$model"
              :state="validateState('lastname')"
            ></b-form-input>
            <b-form-invalid-feedback v-if="$v.form.lastname.$error">
              Lastname is required and at least 4 character.
            </b-form-invalid-feedback>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Company Name</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              type="text"
              class="form-control form-control-lg form-control-solid"
              v-model.trim="$v.form.companyname.$model"
              :state="validateState('companyname')"
            ></b-form-input>
            <b-form-invalid-feedback v-if="$v.form.companyname.$error">
              Companyname at least 4 character.
            </b-form-invalid-feedback>
          </div>
        </div>

        <h5 class="font-weight-bold mt-10 mb-6">Contact Information</h5>
        <div class="separator separator-dashed my-4"></div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Email Address</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-at"></i>
                </span>
              </div>
              <b-form-input
                type="text"
                class="form-control form-control-lg form-control-solid"
                v-model.trim="$v.form.email.$model"
                :state="validateState('email')"
              ></b-form-input>
              <b-form-invalid-feedback v-if="$v.form.email.$error">
                Email is required and valid email.
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Phone Number</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-phone"></i>
                </span>
              </div>
              <b-form-input
                type="text"
                class="form-control form-control-lg form-control-solid"
                v-model.trim="$v.form.phonenumber.$model"
                :state="validateState('phonenumber')"
              ></b-form-input>
              <b-form-invalid-feedback v-if="$v.form.phonenumber.$error">
                Phonenumber is required.
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Nomor HP</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-phone"></i>
                </span>
              </div>
              <b-form-input
                type="text"
                class="form-control form-control-lg form-control-solid"
                v-model.trim="$v.form.customfields1.$model"
                :state="validateState('customfields1')"
              ></b-form-input>
              <b-form-invalid-feedback v-if="$v.form.customfields1.$error">
                Phonenumber at least 6 character.
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Address 1</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <b-form-textarea
                class="form-control form-control-lg form-control-solid"
                v-model.trim="$v.form.address1.$model"
                :state="validateState('address1')"
              ></b-form-textarea>
              <b-form-invalid-feedback v-if="$v.form.address1.$error">
                Address at least 6 character.
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Address 2</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <b-form-textarea
                class="form-control form-control-lg form-control-solid"
                v-model.trim="$v.form.address2.$model"
                :state="validateState('address2')"
              ></b-form-textarea>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >City</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <b-form-input
                class="form-control form-control-lg form-control-solid"
                v-model.trim="$v.form.city.$model"
                :state="validateState('city')"
              ></b-form-input>
              <b-form-invalid-feedback v-if="$v.form.city.$error">
                City at least 6 character.
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >State/Region</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <b-form-input
                class="form-control form-control-lg form-control-solid"
                v-model.trim="$v.form.state.$model"
                :state="validateState('state')"
              ></b-form-input>
              <b-form-invalid-feedback v-if="$v.form.state.$error">
                State/Region at least 6 character.
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Zip Code</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <b-form-input
                class="form-control form-control-lg form-control-solid"
                v-model.trim="$v.form.postcode.$model"
                :state="validateState('postcode')"
              ></b-form-input>
              <b-form-invalid-feedback v-if="$v.form.postcode.$error">
                Postcode at least 6 character.
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Country</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-form-select
              v-model="form.country"
              :options="options"
              class="form-control form-control-solid form-control-lg"
            ></b-form-select>
          </div>
        </div>

        <!-- change password -->
        <h5 class="font-weight-bold mb-6">Change Password</h5>
        <div class="separator separator-dashed my-4"></div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert text-right"
            >Current Password</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              type="password"
              class="form-control form-control-lg form-control-solid mb-2"
              placeholder="Current password"
              v-model.trim="form.password_old"
            />
            <!-- <a href="#" class="text-sm font-weight-bold">Forgot password ?</a> -->
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert text-right"
            >New Password</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              type="password"
              class="form-control form-control-lg form-control-solid"
              placeholder="New password"
              v-model.trim="form.password"
              :state="validateState('password')"
            />
            <strengthMeter
              :strength-meter-only="true"
              v-model="form.password"
            ></strengthMeter>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert text-right"
            >Confirm Password</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              type="password"
              class="form-control form-control-lg form-control-solid"
              placeholder="Confirm password"
              v-model.trim="form.passwordconfirm"
            />
          </div>
        </div>
        <!-- end change password -->
      </div>
    </form>
  </div>
</template>

<script>
import country from "@/assets/rw/country.json";
import strengthMeter from "vue-password-strength-meter";
import { validationMixin } from "vuelidate";
import { ApiURL, notif, loading } from "@/common/mixins/general.js";
import { email, minLength, required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin, ApiURL, notif, loading],
  components: { strengthMeter },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      options: country.option
    };
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    update: function() {
      console.log("eeee", this.$v.form);
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return;

      this.mx_loading(true);
      let form = {
        firstname: this.form.firstname,
        lastname: this.form.lastname,
        companyname: this.form.companyname,
        email: this.form.email,
        phonenumber: this.form.phonenumber,
        no_hp: this.form.customfields1,
        address1: this.form.address1,
        address2: this.form.address2,
        city: this.form.city,
        state: this.form.state,
        postcode: this.form.postcode,
        password_old: this.form.password_old,
        password: this.form.password,
        passwordconfirm: this.form.passwordconfirm
      };
      this.xhr_user
        .post("profile", form)
        .then(({ data }) => {
          this.mx_swal(data.message, "", "success");
        })
        .catch(({ response }) => {
          this.mx_swal(response.data.message);
        })
        .finally(() => {
          this.mx_loading(false);
        });
    }
  },
  validations: {
    form: {
      firstname: { required, minLength: minLength(4) },
      lastname: { required, minLength: minLength(4) },
      companyname: { required },
      email: { required, email },
      phonenumber: { required },
      customfields1: { minLength: minLength(6) },
      address1: { minLength: minLength(6) },
      address2: { minLength: minLength(6) },
      city: { minLength: minLength(6) },
      state: { minLength: minLength(6) },
      postcode: { minLength: minLength(3) },
      password: { minLength: minLength(6) }
      // passwordconfirm: { sameAsPassword: sameAs("password") }
    }
  },
  computed: {
    form() {
      let form = this.user;
      form.password = "";
      form.password_old = "";
      form.passwordconfirm = "";
      return form;
    }
  }
};
</script>

<style scoped>
@import url("../css/style-personal-information.css");
</style>
