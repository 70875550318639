<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Account Information
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Change your account settings</span
        >
      </div>
      <!-- <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success"
          @click="save()"
          ref="kt_save_changes"
        >
          Save Changes
        </button>
      </div> -->
    </div>
    <form class="form">
      <div class="card-body">
        <!-- payment -->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">Payment Method</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-select
              class="form-control form-control-lg form-control-solid"
              :options="paymentmethod"
              v-model="selected.paymentmethod"
            ></b-form-select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label"
            >Account ini adalah</label
          >
          <div class="col-lg-9 col-xl-6">
            <select class="form-control form-control-lg form-control-solid">
              <option value="account pribadi" selected="">
                account pribadi
              </option>
              <option value="account perusahaan dan saya pemiliknya">
                account perusahaan dan saya pemiliknya
              </option>
              <option value="account perusahaan dan saya karyawan">
                account perusahaan dan saya karyawan
              </option>
              <option value="account pelanggan saya">
                account pelanggan saya
              </option>
              <option value="account rekan saya">account rekan saya</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label"
            >Default Billing Contact</label
          >
          <div class="col-lg-9 col-xl-6">
            <select
              name="billingcid"
              id="inputBillingContact"
              class="form-control form-control-lg form-control-solid"
            >
              <option value="0">Use Default Contact (Details Above)</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label"
            >Mengetahui Rumahweb dari</label
          >
          <div class="col-lg-9 col-xl-6">
            <select
              name="customfield[4]"
              id="customfield4"
              class="form-control form-control-lg form-control-solid"
            >
              <option value="">None</option>
              <option value="search engine">search engine</option>
              <option value="majalah">majalah</option>
              <option value="website lain">website lain</option>
              <option value="referensi teman">referensi teman</option>
              <option value="lain - lain">lain - lain</option>
            </select>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label"
            >Berlangganan Newsletter GRATIS</label
          >
          <div class="col-lg-6 col-xl-6">
            <a
              href="https://newsletter.rw.srs-x.net/?p=unsubscribe"
              class="btn btn-outline-danger"
              target="_blank"
              ><i class="fa fa-times"></i> Unsubscribe</a
            >
            &nbsp;
            <a
              href="https://newsletter.rw.srs-x.net/?p=subscribe"
              class="btn btn-outline-success"
              target="_blank"
              ><i class="fa fa-check"></i>Subscribe</a
            >
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      selected: {
        paymentmethod: "none"
      },

      paymentmethod: [
        { value: "none", text: "Use Default (Set Per Order)" },
        { value: "rwfaspaydb", text: "KlikBCA" },
        { value: "rwfaspayklikpay", text: "BCA Klikpay" },
        { value: "ccbii", text: "Credit Card" },
        { value: "rwpaypal", text: "PayPal" },
        { value: "faspaydbva", text: "BCA Virtual Account" },
        { value: "faspaymandiriva", text: "Mandiri Virtual Account" },
        { value: "faspaybniva", text: "BNI Virtual Account" },
        { value: "faspaymaybankva", text: "Maybank Virtual Account" },
        { value: "rwva", text: "Pembayaran ATM" },
        { value: "rwbca", text: "BCA" },
        { value: "rwbri", text: "BRI" },
        { value: "rwniaga", text: "CIMBNIAGA" },
        { value: "rwmandiri", text: "MANDIRI" },
        { value: "rwbni", text: "BNI" },
        { value: "rwbii", text: "MayBank" },
        { value: "voucher", text: "Voucher" },
        { value: "faspayalfamart", text: "Alfamart" },
        { value: "faspayindomaret", text: "Indomaret Payment" },
        { value: "gopay", text: "GoPay" },
        { value: "faspayovo", text: "OVO" },
        { value: "faspayqris", text: "Shopee QRIS" }
      ]
    };
  },
  computed: {
    /*selectedPaymentMethod(){
      return 'none';
    }*/
  },
  mounted() {
    if (typeof this.user.defaultgateway !== "undefined") {
      this.selected.paymentmethod = this.user.defaultgateway;
    }
  }
};
</script>

<style scoped>
.separator.separator-dashed {
  border-bottom: 1px dashed #ebedf3;
}
</style>
