<template>
  <div class="card card-custom">
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">Change Password</h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Reset your account password</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="submit"
          class="btn btn-success"
          @click="save()"
          ref="kt_save_changes"
        >
          Save Changes
        </button>
      </div>
    </div>

    <form class="form" id="kt_password_change_form">
      <div class="card-body">
        <!--begin::Alert-->
        <div
          class="alert alert-custom alert-light-danger fade show mb-10"
          role="alert"
        >
          <div class="alert-icon">
            <i class="fa fa-info-circle fa-2x"></i>
          </div>
          <div class="alert-text font-weight-bold">
            Configure user passwords to expire periodically. <br />
            Users will need warning that their passwords are going to expire, or
            they might inadvertently get locked out of the system!
          </div>
          <div class="alert-close">
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <i class="ki ki-close"></i>
              </span>
            </button>
          </div>
        </div>
        <!--end::Alert-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert"
            >Current Password</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              type="password"
              class="form-control form-control-lg form-control-solid mb-2"
              placeholder="Current password"
              name="current_password"
              v-model="password.password_old"
            />
            <!-- <a href="#" class="text-sm font-weight-bold">Forgot password ?</a> -->
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert"
            >New Password</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              type="password"
              class="form-control form-control-lg form-control-solid"
              placeholder="New password"
              name="new_password"
              v-model="password.password"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert"
            >Verify Password</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              type="password"
              class="form-control form-control-lg form-control-solid"
              placeholder="Verify password"
              name="verify_password"
              v-model="password.passwordconfirm"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { breadcrumb, loading, ApiURL, notif } from "@/common/mixins/general.js";
export default {
  name: "slotChangePassword",
  mixins: [breadcrumb, loading, ApiURL, notif],
  data() {
    return {
      password: {
        password: "",
        password_old: "",
        passwordconfirm: ""
      }
    };
  },

  methods: {
    save() {
      if (this.password.password_old !== "") {
        if (this.password.password !== "") {
          if (this.password.passwordconfirm !== "") {
            this.mx_loading();
            ApiService.setHeader();
            ApiService.post(this.api.user.change_password, this.password)
              .then(({ data }) => {
                this.mx_swal(data.message, "", "success");
                this.reset();
              })
              .catch(({ response }) => {
                this.mx_swal(response.data.message);
              })
              .finally(() => {
                this.mx_loading(false);
              });
          } else {
            this.mx_swal("Verify password is empty");
          }
        } else {
          this.mx_swal("New password is empty");
        }
      } else {
        this.mx_swal("Current password is empty!");
      }
    },
    reset() {
      this.password = {
        password: "",
        password_old: "",
        passwordconfirm: ""
      };
    }
  }
};
</script>

<style scoped>
@import url("../css/style.css");
@import url("../css/style-change-password.css");
</style>
