<template>
  <div class="d-flex flex-row flex-wrap">
    <div
      class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
      id="kt_profile_aside"
    >
      <div class="card card-custom card-stretch">
        <div class="card-body pt-4 mt-4">
          <div class="d-flex align-items-center">
            <div>
              <a
                class="
                  font-weight-bolder font-size-h5
                  text-dark-75 text-hover-primary
                "
                >{{ user.firstname }} {{ user.lastname }}</a
              >
              <div class="text-muted">{{ user.companyname }}</div>
            </div>
          </div>
          <!--end::User-->
          <!--begin::Contact-->
          <div class="my-4">
            <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2">Email:</span>
              <a class="text-muted text-hover-primary">{{ user.email }}</a>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2">Phone:</span>
              <span class="text-muted">{{ user.phonenumber }}</span>
            </div>
          </div>
          <!--end::Contact-->
          <!--begin::Nav-->
          <div
            class="navi navi-bold navi-hover navi-active navi-link-rounded"
            role="tablist"
          >
            <div class="navi-item mb-2" v-if="!user.ismain">
              <a
                class="navi-link py-4"
                @click="setActiveTab"
                data-tab="0"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M18 14a3 3 0 110-6 3 3 0 010 6zm-9-3a4 4 0 110-8 4 4 0 010 8z" fill="#000" fill-rule="nonzero" opacity=".3"/><path d="M17.601 15c3.407.038 6.188 1.76 6.397 5.4.009.147 0 .6-.542.6H19.6c0-2.25-.744-4.328-1.999-6zm-17.6 5.2C.388 15.426 4.26 13 8.983 13c4.788 0 8.722 2.293 9.015 7.2.012.195 0 .8-.751.8H.727c-.25 0-.747-.54-.726-.8z" fill="#000" fill-rule="nonzero"/></g></svg>
                  </span>
                </span>
                <span class="navi-text font-size-lg">Main Profile</span>
              </a>
            </div>
            <div class="navi-item mb-2">
              <a
                class="navi-link py-4 active"
                @click="setActiveTab"
                data-tab="1"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M12 11a4 4 0 110-8 4 4 0 010 8z" fill="#000" fill-rule="nonzero" opacity=".3"/><path d="M3 20.2c.388-4.773 4.262-7.2 8.983-7.2 4.788 0 8.722 2.293 9.015 7.2.012.195 0 .8-.751.8H3.727c-.25 0-.747-.54-.726-.8z" fill="#000" fill-rule="nonzero"/></g></svg>
                  </span>
                </span>
                <span class="navi-text font-size-lg">Personal Information</span>
              </a>
            </div>
            <div class="navi-item mb-2">
              <a
                class="navi-link py-4"
                @click="setActiveTab"
                data-tab="2"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M2.56 10.682l2.122-2.121a1.5 1.5 0 012.121 0l2.122 2.121a1.5 1.5 0 010 2.121l-2.122 2.122a1.5 1.5 0 01-2.121 0l-2.121-2.122a1.5 1.5 0 010-2.121zm12 0l2.122-2.121a1.5 1.5 0 012.121 0l2.122 2.121a1.5 1.5 0 010 2.121l-2.122 2.122a1.5 1.5 0 01-2.121 0l-2.121-2.122a1.5 1.5 0 010-2.121z" fill="#000" opacity=".3"/><path d="M8.56 16.682l2.122-2.121a1.5 1.5 0 012.121 0l2.122 2.121a1.5 1.5 0 010 2.121l-2.122 2.122a1.5 1.5 0 01-2.121 0l-2.121-2.122a1.5 1.5 0 010-2.121zm0-12l2.122-2.121a1.5 1.5 0 012.121 0l2.122 2.121a1.5 1.5 0 010 2.121l-2.122 2.122a1.5 1.5 0 01-2.121 0L8.561 6.803a1.5 1.5 0 010-2.121z" fill="#000"/></g></svg>
                  </span>
                </span>
                <span class="navi-text font-size-lg">Account Information</span>
              </a>
            </div>

            <div class="navi-item mb-2">
              <a class="navi-link py-4" @click="goto('npwp')">
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M5.857 2h7.88a1.5 1.5 0 01.968.355l4.764 4.029A1.5 1.5 0 0120 7.529v12.554c0 1.79-.02 1.917-1.857 1.917H5.857C4.02 22 4 21.874 4 20.083V3.917C4 2.127 4.02 2 5.857 2z" fill="#000" fill-rule="nonzero" opacity=".3"/><rect fill="#000" x="6" y="11" width="9" height="2" rx="1"/><rect fill="#000" x="6" y="15" width="5" height="2" rx="1"/></g></svg>
                  </span>
                </span>
                <span class="navi-text font-size-lg"
                  >Update Tax Information</span
                >
              </a>
            </div>

            <div class="navi-item mb-2">
              <b-link
                class="navi-link py-4"
                :to="{ path: 'profile/sub-account' }"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M18 8h-2a1 1 0 010-2h2V4a1 1 0 012 0v2h2a1 1 0 010 2h-2v2a1 1 0 01-2 0V8zm-9 3a4 4 0 110-8 4 4 0 010 8z" fill="#000" fill-rule="nonzero" opacity=".3"/><path d="M0 20.2C.389 15.426 4.263 13 8.984 13c4.788 0 8.722 2.293 9.015 7.2.012.195 0 .8-.751.8H.727c-.25 0-.747-.54-.726-.8z" fill="#000" fill-rule="nonzero"/></g></svg>
                  </span>
                </span>
                <span class="navi-text font-size-lg">Sub Account</span>
              </b-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::Content-->
    <div class="flex-row-fluid ml-lg-8">
      <b-tabs class="hide-tabs" v-model="tabIndex">
        <b-tab>
          <RWMainProfile :user="user"></RWMainProfile>
        </b-tab>
        <b-tab active>
          <RWPersonalInformation :user="user"></RWPersonalInformation>
        </b-tab>
        <b-tab>
          <RWAccountInformation :user="user"></RWAccountInformation>
        </b-tab>
        <b-tab>
          <RWChangePassword></RWChangePassword>
        </b-tab>
      </b-tabs>
    </div>
    <!--end::Content-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RWChangePassword from "@/views/pages/profile/slot/slotChangePassword";
import RWAccountInformation from "@/views/pages/profile/slot/slotAccountInformation";
import RWPersonalInformation from "@/views/pages/profile/slot/slotPersonalInformation";
import RWMainProfile from "@/views/pages/profile/slot/slotMainProfile.vue";
import {
  breadcrumb,
  loading,
  ApiURL,
  notif,
  util
} from "@/common/mixins/general.js";

export default {
  name: "profile-overview",
  mixins: [breadcrumb, loading, ApiURL, notif, util],
  components: {
    RWPersonalInformation,
    RWAccountInformation,
    RWChangePassword,
    RWMainProfile
  },
  data() {
    return {
      tabIndex: 0
    };
  },
  methods: {
    /**
     * ----------------------------------------------
     * Set current active on click
     * @param event
     * ----------------------------------------------
     */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");

      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
        let svgCurrent = links[i].querySelector("svg");
        svgCurrent.classList.remove("kt-svg-icon", "kt-svg-icon--primary");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
      let svg = target.querySelector("svg");
      svg.classList.add("kt-svg-icon", "kt-svg-icon--primary");
    },

    /**
     * ----------------------------------------------
     * get link to external / out from local
     * ----------------------------------------------
     */
    goto: function(file_name_whmcs) {
      this.mx_loading();
      this.xhr_tools
        .get(this.api.tools.redirect.index, `?file=${file_name_whmcs}`)
        .then(({ data }) => {
          this.mx_redirect(data.data.url, false);
        })
        .catch(({ response }) => {
          this.mx_swal(response.data.message);
        })
        .finally(() => {
          this.mx_loading(false);
        });
    }
  },
  computed: {
    ...mapGetters(["user"])
  },
  mounted() {
    this.set_breadcrumb([{ title: "Profile", route: "profile" }]);
  }
};
</script>

<style scoped>
@import url("css/style-profile-menu.css");
</style>
